/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

/* font-family: "Poppins", sans-serif;
font-family: "Luckiest Guy", cursive;
font-family: "Oswald", sans-serif;
font-family: "Oxanium", cursive;
font-family: "Poppins", sans-serif; */

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


:root {
  --top: 50%;
  --left: 50%;
  --tornadoRotate: '180deg';
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.chess-layout {
  background: rgb(108,108,108);
  background: radial-gradient(circle, rgb(100, 100, 100) 9%, rgba(43,43,43,1) 36%, rgba(36,34,34,1) 45%);
  font-family: 'Play', sans-serif !important;
  font-weight: 500 !important;
  scroll-behavior: smooth;
  overflow-x: hidden;
  height: 100dvh;
}

*::-webkit-scrollbar {
  height: 2px;
  width: 3px;
}

*::-webkit-scrollbar-track {
  border-radius: 2px;
  background-color: #727272;
  border: 1px solid #949494;
}

*::-webkit-scrollbar-track:hover {
  background-color: #ff9e4f;
}

*::-webkit-scrollbar-track:active {
  background-color: #646464;
}

*::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: #6b6b6b;
  border: 3px solid #505050da;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #62A34B;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #62A34B;
}


button:active {
  scale: .95;
}





.font-aclonica {
  font-family: "Poppins" !important;
}

.modal-backdrop {
  width: 100dvw;
  height: 100dvh;
  background: black;
  background: #373535cc;
  position: absolute;
  top: 0;
}

.chess-board {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
}

.chessboard-loader {
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: radial-gradient(circle, #292929e5, #212121, #191919, #101010, #020202);
    width: 100%;
    height: 100%;
    z-index: 1000;
    /* z-index: 1; */
    display: grid;
    place-items: center;
    top: 0%;
    border: .5rem solid rgb(51, 50, 49);
    scale: 1.05;
    animation: fadeInout .5s ease-in infinite;
}


@keyframes fadeInout {
   100% {
     opacity: .4;
   }
}


.chessboard-loader > iframe {
   width: 20rem;
   height: 20rem;
}

.app-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* background-color: rgb(34, 34, 34); */
}

.chessboard-container {
  width: 800px;
  height: 800px;
}


.chessRightBox {
  width: 100%;
  border-radius: 2rem;
  height: 90%;
  /* border: 2px solid gray; */
}

.square {
  width: 12.5%;
  height: 12.5%;
  position: relative;
}


.board-number {
  z-index: 1;
  position: absolute;
  right: -17px;
  color: #b4b1b1;
  top: 50%;
  font-size: .8rem;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.board-text {
  z-index: 1;
  position: absolute;
  bottom: -3rem;
  color: #b4b1b1;
  right: 50%;
  font-size: .9rem;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}


.square-box {
  width: 100%;
  height: 100%;
}

.position {
  position: absolute;
  left: 5px;
  font-size: 12px;
}

.square-box-container {
  width: 100%;
  height: 100%;
}

/* .square-black {
  position: relative;
}

.square-black::before {
   content: "";
   width: 50%;
   background: green;
   height: 50%;
   position: absolute;
   top: 50%;
   left : 50%;
   z-index: 2;
   border-radius: 50%;
   transform: translate(-50%,-50%);
} */

/* .square-box:hover {
  border: 0.4rem solid #62626299;
} */

.aside-left {
  /* background: rgb(29 29 29); */
  /* border: 1px solid rgb(39, 38, 38); */
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  height: 100dvh;
  /* margin-top: 1rem; */
  border-radius: 10px;
  /* width: auto; */
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}



.aside-right {
  /* background: rgb(29 29 29); */
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  display: grid;
  place-items: center;
  /* border: 1px solid rgb(39, 38, 38); */
}

.aside-left::-webkit-scrollbar,
.aside-right::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  background-color: rgb(56, 56, 56);
}


.aside-left::-webkit-scrollbar,
.aside-right::-webkit-scrollbar {
  box-shadow: inset 0 0 6px rgb(48, 48, 48);
}


.aside-left::-webkit-scrollbar,
.aside-right::-webkit-scrollbar {
  background-color: rgb(36, 36, 36);
  outline: 1px solid rgb(41, 41, 41);
  border-radius: 5px;
}


.piece-container{
  cursor: -webkit-grab;
  cursor: grab;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: 1s linear;
  -o-transition: 1s linear;
  transition: 1s linear;
  position: absolute;
}

.piece {
  max-width: 82%;
  max-height: 90%;
  background-color: transparent !important;
  position: relative;
  z-index: 99;
  /* scale: .9; */
  transition: scale 500ms cubic-bezier(.06, .59, .06, 1.42);
  /* filter: drop-shadow(2.5px 5px 1px rgba(0, 0, 0, 0.774)); */
}

/* .piece-type-p {
  z-index: 98;
} */



@keyframes scale {
   from { 
     scale: .7;
   }
   to {
     scale: 1;
   }
}


.promote-modal {
  position: absolute;
  z-index: 9999;
  left: 0;
  width: 12.5%;
  height: 12.5%;
}


.promote-modal-backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  /* top: -2%; */
  background: #08080891;
  z-index: 9998;
  transform: scaleY(1.010);
}

.promotion-piece {
  width: 100%;
  height: 100px;
  background: -o-radial-gradient(circle, rgb(231, 231, 231) 13%, rgb(128, 128, 128) 91%);
  background: radial-gradient(circle, rgb(231, 231, 231) 13%, rgb(128, 128, 128) 91%);
  -webkit-transition: all .1s ease-in;
  -o-transition: all .1s ease-in;
  transition: all .1s ease-in;
  display: -ms-grid;
  display: grid;
  place-items: center;
  /* border-radius: 50%; */
}

.promotion-piece:hover {
  background: -o-radial-gradient(circle, rgba(251, 251, 251, 1) 11%, rgb(190, 115, 53) 73%);
  background: radial-gradient(circle, rgba(251, 251, 251, 1) 11%, rgb(190, 115, 53) 73%);
  border-radius: 0%;
}

.cursor-pointer {
  cursor: pointer;
}

.vertical-text {
  text-orientation: upright;
  -webkit-writing-mode: vertical-lr;
  -ms-writing-mode: tb-lr;
  writing-mode: vertical-lr;
  font-family: 'Poppins';
  padding: 10px;
  color: #fff;
}

.vertical-text button {
  margin-top: 20px;
  cursor: pointer;
  background-color: rgb(63, 63, 63);
  border: 1px solid #fff;
  /* border-radius: 1px;  */
}

.user-form {
  margin-top: 30px !important;
  max-width: 800px;
  margin: auto;
}

.home {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
}

.button-div {
  min-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.button-div button {
  cursor: pointer;
  padding: 8px 18px;
  /* background-color: rgb(255, 255, 255); */
  background-color: transparent;
  color: #fff;
  font-weight: 600;
  border: 1px solid #fff;
}

.button-div button:hover {
  background-color: goldenrod;
  color: white;
}

.option-piece {
  cursor: pointer;
  padding: 8px 18px;
  background-color: rgb(255, 255, 255);
  color: #000000;
  font-weight: 600;
  border: 1px solid #000000;
}

.sound-setting {
  margin-top: 1rem !important;
  border-radius: 8px;
  padding: 1rem;
  color: #fff !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  border: 1px solid #333333ac;
  justify-content: space-between;
  background-color: #2d3436;
  background-image: linear-gradient(315deg, #0a0a0aab 0%, #232323 74%);
  -webkit-box-shadow: 0px 3px 5px rgba(22, 22, 22, 0.164);
  box-shadow: 0px 3px 5px rgba(22, 22, 22, 0.164);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}





.move {
  background: hsla(239, 100%, 67%, 1);

  background: linear-gradient(90deg, hsla(239, 100%, 67%, 1) 0%, hsla(187, 100%, 89%, 1) 100%);

  background: -moz-linear-gradient(90deg, hsla(239, 100%, 67%, 1) 0%, hsla(187, 100%, 89%, 1) 100%);

  background: -webkit-linear-gradient(90deg, hsla(239, 100%, 67%, 1) 0%, hsla(187, 100%, 89%, 1) 100%);

  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#595CFF", endColorstr="#C6F8FF", GradientType=1);
  padding: 8px 16px;
  color: rgb(17, 17, 17);
  border-radius: 50px;
  font-size: 12px;

}

.loader {
  display: flex;
  align-items: center;
}

.bar {
  display: inline-block;
  width: 3px;
  height: 10px;
  background-color: rgba(255, 255, 255, .5);
  border-radius: 10px;
  animation: scale-up4 1s linear infinite;
}

.bar:nth-child(2) {
  height: 20px;
  margin: 0 5px;
  animation-delay: .25s;
}

.bar:nth-child(3) {
  animation-delay: .5s;
}

@keyframes scale-up4 {
  20% {
    background-color: #ffff;
    transform: scaleY(1.5);
  }

  40% {
    transform: scaleY(1);
  }
}



.option-piece:hover {
  background-color: goldenrod;
}


.join-btn:active {
  -webkit-box-shadow: none;
  box-shadow: none;
  position: relative;
  top: 0.2em;
}

.join-btn:hover {
  opacity: 1;
  -webkit-transform: scale(.95);
  -ms-transform: scale(.95);
  transform: scale(.95);
}

.join_room_class {
  font-family: "Poppins";
  font-weight: 300;
  background: #1b1b1b;
  max-height: 80%;
  height: 15rem !important;
  border-radius: 1.5rem;
  box-shadow: 0px 0px 10px 10px rgba(43, 43, 43, 0.445);
}

.join_room_class button {
  width: 50%;
  background: rgb(21, 132, 175);
  border: none;
  outline: none;
  padding: 1rem 0rem !important;
  border-radius: 50px;
  outline: 4px solid transparent;
  transition: outline .2s linear;
  opacity: .8;
  font-size: 1.2rem;
}

.join-info-text {
  background: #292625;
  display: flex;
  justify-content: space-between;
  padding: .5rem 1rem;
  border-radius: 50px;
  outline: 2px solid #3d342fe0;
  gap: .5rem;
  align-items: center;
  transition: all .3s linear;
  /* cursor: pointer !important; */
}

.join-info-text:hover {
  /* outline: 3px solid rgba(70, 70, 70, 0.671); */
  outline-offset: 2px;
}


.join_room_class button:hover {
  opacity: 1;
  outline: 4px solid rgba(6, 124, 179, 0.384);
  text-transform: uppercase;
}

.join_room_class button:active {
  scale: .95 !important;
}

@media screen and (max-width : 400px) {
  .join_room_class {
    width: 100% !important;
  }
}

/* ---------------------------------------------------------{NAvbar}---------------------------------------------------------  */

.navbar-toggler {
  display: none;
}

.transasction-link {
  text-decoration: none;
}

.nav-item:hover {
  background-color: #393b3c;
  border-radius: 10px;
  -webkit-transition: all .2s ease;
  -o-transition: all .2s ease;
  transition: all .2s ease;
}

.nav-item>a:hover {
  color: goldenrod !important;
}

.menu-icon {
  background-color: rgb(42, 42, 42);
  border-radius: 5px;
  display: none;
  padding: 2.5px 5px;
}

.navbar-dark .navbar-nav .nav-link.active {
  color: rgb(255, 255, 255) !important;
  border-radius: 10px;
  background: #393B3C;
}

.nav-link {
  font-family: "Poppins";
}

.walletInfo {
  color: #fff;
  background-color: #5865f2;
  outline: 2px solid rgb(138, 159, 255);
  border-radius: 5px;
  text-align: left;
}

.discord_btn {
  color: #fff;
  background-color: #5865f2;
  border-radius: 100%;
  font-size: large;
  width: 1.875rem;
  height: 1.875rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}


#wallet-dropdown {
  color: #fff;
  background: #393B3C;
  position: absolute;
  top: 4rem;
  right: 22rem;
  border-radius: 1rem;
  margin-top: 0.5rem;
  z-index: 5;
}

#dropdown-basic {
  border-radius: 50px;
}

.dropdown-item:hover {
  background-color: #282828;
}


.dropdown-menu {
  -webkit-transform: translate(10px, 40px) !important;
  -ms-transform: translate(10px, 40px) !important;
  transform: translate(10px, 40px) !important;
}

.dropdown-toggle::after {
  display: none;
}

/* --------------------------------------------{HOME}----------------------------------------------- */

.grey_container {
  background: rgba(40, 42, 44, 0.6);
  backdrop-filter: blur(22px);
  -webkit-backdrop-filter: blur(22px);
  color: #fff;
  border-radius: 12px;
  max-width: 100%;
  height: auto;
}

.select_Game_Type {
  background: rgba(40, 42, 44, 0.60);
  -webkit-backdrop-filter: blur(22px);
  backdrop-filter: blur(22px);
  /* Note: backdrop-filter has minimal browser support */
  font-family: 'Poppins';
  border-radius: 32px;
  -webkit-box-shadow: 0px 0px 10px 10px rgba(29, 28, 28, 0.145);
  box-shadow: 0px 0px 10px 10px rgba(29, 28, 28, 0.145);
}

.lobby_icons {
  width: 1.725rem;
  height: 1.725rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.table>thead {
  background: #262728;
  position: sticky;
  top: 0;
}

td {
  border-bottom-width: 0px !important;
}

.select_icon>span {
  color: #cefa51;
  width: 2.625rem;
  height: 2.625rem;
  background-color: rgba(206, 254, 70, 0.2);
  margin-right: 1rem;
  font-size: large;
  border-radius: 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.select_icon {
  font-family: "Poppins";
  font-style: normal;
  font-weight: lighter;
  font-size: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.select_time_box {
  background: #5a5a5a;
  border-radius: 1.1rem;
  font-family: "Poppins";
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border: 1px solid #5a5a5a;
  cursor: pointer;
}



.select_time_box:hover {
  background: -o-linear-gradient(358.5deg,
      rgba(238, 185, 19, 0.1) 1.27%,
      rgba(235, 167, 26, 0.1) 35.77%,
      rgba(197, 140, 21, 0.1) 74.59%,
      rgba(242, 182, 22, 0.1) 104.78%);
  background: linear-gradient(91.5deg,
      rgba(238, 185, 19, 0.1) 1.27%,
      rgba(235, 167, 26, 0.1) 35.77%,
      rgba(197, 140, 21, 0.1) 74.59%,
      rgba(242, 182, 22, 0.1) 104.78%);
  border: 1px solid #fbc417;
}

.select_time_box>span {
  font-family: "Poppins";
  font-size: 24px;
}

/* 
.select_timez {
  padding: 7rem 1rem 1rem;
} */



.select_main_heading {
  /* position: absolute;
  left: 0px;
  top: 0px; */
  background: #212020;
  border-bottom: 1px solid #5a5a5a;
  border-radius: 20px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.select_main_heading p {
  font-family: "Poppins";
  font-style: normal;
  font-size: large;
  font-weight: 400;
}

/* .select_main_heading > div > button {
  background-color: #5865f2;
} */
.tabs-link {
  color: #fff;
}

.tabs-link:hover {
  color: #fff;
}

.tabActive {
  background: #133667;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #206ce3;
  border-radius: 12px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;

}

.active-tab {
  background: #133667;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #206ce3;
  border-radius: 12px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.active-tab:hover {
  color: #fff;
}

.selectbuttonActive {
  background: #3b3b3b;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 9px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid gray;
  -webkit-transition: all .1s linear;
  -o-transition: all .1s linear;
  transition: all .1s linear;
}

.selectbuttonActive:hover {
  color: #fff;
  opacity: .8;
  border: 1px solid gold;
}

.selectbuttonActive>span {
  background-color: #fff;
  width: 2rem;
  height: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 4px;
}

.Create_Game_heading {
  background-color: #5a5a5a;
  border-radius: .8rem;
  font-size: 18px;
  letter-spacing: 1px;
  padding: 0.5rem 1rem;
  width: 100%;
}

.create_game>button {
  letter-spacing: 1px;
  background: #393b3c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #5a5a5a;
  border-radius: 1rem;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 1rem;
}

.select_piece_img {
  /* background: #3c3e42; */
  background: rgb(117, 117, 117);
  background: -o-radial-gradient(circle, rgba(117, 117, 117, 1) 1%, rgba(60, 62, 66, 1) 27%);
  background: radial-gradient(circle, rgba(117, 117, 117, 1) 1%, rgba(60, 62, 66, 1) 27%);

  border-radius: 1.1rem;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  /* padding: 1.5rem; */
  border: 1px solid #5a5a5a;
  cursor: pointer;
}

.select_piece_img>img {
  max-width: 3.875rem;
}

.select_piece_img:hover {
  background: rgb(232, 194, 104);
  background: -o-radial-gradient(circle, rgba(232, 194, 104, 0.377) 1%, rgba(144, 113, 54, 0.205) 29%);
  background: radial-gradient(circle, rgba(232, 194, 104, 0.377) 1%, rgba(144, 113, 54, 0.205) 29%);
  border: 1px solid #fbc417;
}

.select_img_div {
  background: -o-linear-gradient(358.5deg,
      rgba(238, 185, 19, 0.1) 1.27%,
      rgba(235, 167, 26, 0.1) 35.77%,
      rgba(197, 140, 21, 0.1) 74.59%,
      rgba(242, 182, 22, 0.1) 104.78%);
  background: linear-gradient(91.5deg,
      rgba(238, 185, 19, 0.1) 1.27%,
      rgba(235, 167, 26, 0.1) 35.77%,
      rgba(197, 140, 21, 0.1) 74.59%,
      rgba(242, 182, 22, 0.1) 104.78%);
  border: 1px solid #fbc417;
}

.select_piece_container p {
  padding-top: 3rem;
  font-family: "Poppins";
  letter-spacing: 0.063rem;
}


.board-selection {
  border-radius: 10px;
  background: #504f49;
  border: 1px solid gray;
  color: #fff;
  width: 60%;
  font-size: .8rem;
  margin-left: 1rem;
  padding-left: 1rem;
  cursor: pointer;
}

.active-tab {
  color: #fff;
}

.active-tab:hover {
  color: #fff;
}








.getBoard {
  background-color: #1b1a1d;
  border: 1px solid #5a5a5a;
  letter-spacing: 0.05rem;
}

.getBoard:hover {
  background-color: #f9cb3e;
  border: 1px solid#fbc417;
  color: #000000;
}



/* -------------------------------------{market_place_sidebar}------------------------------------------------- */




.shop-history-marketplace-list:hover {
  border: 1px solid #3b3b3b;
}

.shop-history-marketplace-list img {
  border-radius: 5px;
  width: 40px;
  height: 40px;
}

.modal-content {
  color: #fff;
  border: 5px;
}



.modal_prize_card {
  background: #282a2c;
  border-radius: 0.5rem;
  font-family: "Poppins";
  font-size: 14px;
  border: 1px solid #5a5a5a;
  cursor: pointer;
}

.modal_prize_card:hover {
  border: 1px solid #fbc417;
}

.modal_prize_card_select {
  background: #322c1d;
  border: 1px solid #fbc417;
}

.modal_butn {
  font-family: "Poppins";
}

.bot-div {
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px !important;
}

.bot-level {
  width: 100%;
  border-right: 1px solid #404040;
  cursor: pointer;
}

.bot-level:hover {
  background-color: #5a5a5a;
}

.bot-level-first {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}



.bot-level-last {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}



.traditionalFreeGame img {
  width: 5rem;
}

.selectgameTypeButton {
  letter-spacing: 1px;
  background: #393b3c;
  border: 1px solid #5a5a5a;
  border-radius: 8px;
  font-family: "Poppins";
  text-align: center;
  color: #fff;
  padding: 0.75rem;
  cursor: pointer;
}

.selectpwcButton {
  letter-spacing: 1px;
  background: transparent;
  border: 1px solid #F9CB3E;
  border-radius: 8px;
  font-family: "Poppins";
  text-align: center;
  color: #fbc417;
  padding: 0.75rem;
  cursor: pointer;
}


.traditionalFreeGame:hover .selectgameTypeButton {
  background: #fbc417;
  color: #000000;
}

.copylinkbutton {
  /* font-size: small; */
  color: #f9cb3e;
  cursor: pointer;
}

.linksharecancelbtn {
  letter-spacing: 1px;
  width: 100%;
  cursor: pointer;
  background: #393b3c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #5a5a5a;
  border-radius: 1rem;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 1rem;
}

.linksharecancelbtn:hover {
  background: #1b1a1d;
}

.exitbluebutton {
  background: #133667 !important;
  border: 1px solid #206ce3 !important;
  border-radius: 12px !important;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  color: #fff !important;
}

.create-info-modal {
  font-family: Montserrat;
  border-radius: 99px;
  border: 1px solid #ffb800;
  background: rgba(245, 197, 72, 0.1);
}

.creategame-slectoption-btn {
  border: 1px solid #4d4d4d;
  border-radius: 3px;
}

.creategame-slectoption-btn:hover {
  border: 1px solid #ffb800;
  cursor: pointer;
}

.board-img-container:hover {
  border-radius: 6px;
  border: 1px solid #f9cb3e;
  background: -o-linear-gradient(353deg,
      rgba(238, 185, 19, 0.1) 0%,
      rgba(235, 167, 26, 0.1) 33.33%,
      rgba(197, 140, 21, 0.1) 70.83%,
      rgba(242, 182, 22, 0.1) 100%);
  background: linear-gradient(97deg,
      rgba(238, 185, 19, 0.1) 0%,
      rgba(235, 167, 26, 0.1) 33.33%,
      rgba(197, 140, 21, 0.1) 70.83%,
      rgba(242, 182, 22, 0.1) 100%);
  color: #f9cb3e;
  cursor: pointer;
}

.board-img-container {
  width: 11rem;
  height: 10rem;
  border-radius: 6px;
  border: 1px solid #5a5a5a;
  background: #1b1a1d;
}

/* active tournament */
.listContainer,
.playercontainer {
  background: #333333;
  border: 1px solid #4b4949;
  margin-top: .5rem;
  border-radius: 5px;
  box-shadow: 0px 0px 20px 2px #212020;
  transition: border .4s ease-in;
}

.scroes span {
  font-size: .8rem;
}

.playercontainer {
  height: 5.5rem;
}



.playIcon:active {
  scale: .95;
}

.listContainer {
  height: auto;
}

.listContainer:hover,
.playercontainer:hover {
  border-radius: 0.5rem;
  border: 1px solid #616161;
  background: #302e2c;
  cursor: pointer;
}


/* upcoming tournament */





.time-mobile-450 {
  display: none;
  width: 85%;
}


.gameActions {
  cursor: pointer;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: grid;
  place-items: center;
}

.gameActions:hover {
  background-color: #303030;
}

.yellowActiveBtn {
  border-radius: 0.5rem;
  border: 1px solid #d69817;
  background: #f9cb3e;
  color: #000;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}

.yellowBtnOutline {
  border-radius: 0.5rem;
  border: 1px solid #f9cb3e;
  color: #f9cb3e;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}

.yellowActiveBtn :hover {
  color: #000;
}

.yellowBtnOutline :hover {
  color: #f9cb3e;
}



.text-primary {
  color: rgb(32, 161, 248) !important;
}

.playing-player {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: .7rem !important;
}



@keyframes cardTransform {
  from {
    opacity: .1;
    transform: translateY(40px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

/* .card_image {
  overflow: hidden;
}

.card:hover img {
  transform: scale(1.1);
}
.card img {
  transition: transform 0.5s;
} */

/* border: 0.4rem solid #62626299; */
/* ========================================================================================================================= */

.detail-sm {
  display: none !important;
}

/* -------------------------- Modal -------------- */

/* playwith friend modal css */
.selectpwcButton {
  letter-spacing: 1px;
  background: transparent;
  border: 1px solid #f9cb3e;
  border-radius: 8px;
  font-family: "Poppins";
  text-align: center;
  color: #fbc417;
  padding: 0.75rem;
  cursor: pointer;
}

.selectpwcButton:hover {
  background: #f9cb3e;
  cursor: pointer;
  color: #000000;
  border: 1px solid #fbc417;
}




.square-highlight-animate {
  animation: fadeInout 2s linear infinite alternate;
}

.waiting-popup {
  max-width: 60%;
  width: 30rem;
  background-image: linear-gradient(315deg, #0a0a0ae7 0%, #222020 74%);
  padding: 1rem;
  border-radius: 5px;
  border-left: 1px solid #ff2121;
  position: fixed;
  bottom: 0;
  right: 0;
  height: 7rem;
  z-index: 2;
  text-align: center;
  display: none;
}

.waiting-popup p {
  padding: 0;
  margin: 0;
}

.player-wait-timer-container {
  background-color: rgb(56, 56, 56);
  border: 1px solid rgb(102, 99, 99);
  box-shadow: 1px 1px 0px 0px #797979;
  width: 30rem;
  min-width: 30rem;
  height: 5rem;
  border-radius: 5px;
  position: absolute;
  bottom: 1%;
  right: -50%;
  z-index: 10;
}

.timer-open {
  animation: slide-open .5s linear forwards;
}

.timer-close {
  animation: slide-close .5s linear forwards;
}

@keyframes slide-open {
  from {
    right: -50%;
  }

  to {
    right: 0%;
  }
}

@keyframes slide-close {
  from {
    right: 0%;
  }

  to {
    right: -50%;
  }
}






@keyframes fadeInout {
  0% {
    opacity: .7;
  }

  50% {
    opacity: .8;
  }

  100% {
    opacity: 1;
  }
}



@keyframes fadeIn {
  0% {
    opacity: .6;
    scale: 0;
  }

  100% {
    opacity: 1;
    scale: 1;
  }
}

@keyframes scaling {
  0% {
    scale: 3.5;
  }

  100% {
    scale: .4;
    transform: translate(-20%, -20%);
  }

}

@keyframes scaleUp {
  from {
    scale: 0;
  }

  to {
    scale: 1;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}







.nft-canvas {
  background-color: #1f1f1fe1;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 12;
  bottom: -100%;
  left: 0;
  transition: bottom .1s ease-in;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  overflow-y: auto;
  overflow-x: hidden;
}


/* @keyframes translate {
   0% {
     left: 10%;
   }
   25% {
     left: 80%;
   }
   50% {
     top : 40%;
   }
   75% {
     top : 80%;
     left: 40%;
   }
} */



@-webkit-keyframes signing {
  0% {
    left: -75px;
  }

  100% {
    left: 120%;
  }
}



@keyframes signing {
  0% {
    left: -75px;
  }

  100% {
    left: 120%;
  }
}

@-webkit-keyframes fadeInOut {
  0% {
    opacity: .7;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: .7;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: .7;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: .7;
  }
}

.explosion-animation {
  width: 74px;
  height: 74px;
  /* background-image: url('./assets/icons/explosion-spritesheet.png'); */
  background-position: -654px -654px;
  border: 1px solid #fff;
}


@media screen and (max-width : 1482px) {
  .chessboard-container {
    width: 500px;
    height: 500px;
  }
}


@media screen and (max-width : 1440px) {

  .preview {
    top: -2% !important;
    left: -14% !important;
  }

  .game-data span {
    font-size: 1rem;
  }

  #wallet-dropdown {
    right: 23%;
    top: 90%;
    font-size: .9rem;
  }

}

@media screen and (max-width: 1280px) {
  #navbarTogglerDemo01 ul {
    font-size: 10px;
  }

  .container-fluid {
    width: 100% !important;
  }

  .chessboard-container {
    width: 450px;
    height: 450px;
  }

  .select_time_box {
    font-size: 1rem;
  }

  .select_time_box>span {
    font-size: 1rem;
  }

  .msg_chessboard_Box p {
    top: 18%;
  }

  .game-data span {
    font-size: .8rem;
  }

  .moveHistory {
    font-size: .7rem;
    font-weight: 100 !important;
  }


}

@media screen and (max-width : 1024px) {
  .promotion-piece {
    height: 100px;
  }

  .chessboard-container {
    width: 440px;
    height: 440px;
    font-size: .8rem;
  }

  .chessRightBox>div,
  .chessLeftBox>div {
    padding: 0 !important;
  }

  .abort_game {
    padding: .5rem !important;
    font-size: .5rem !important;
  }

  .powers>.powers-name {
    font-size: 10px !important;
  }

  .powers>button {
    padding: .5rem !important;
    font-size: 10px !important;
  }

}




.section-top-2 {
  display: none;
}


.preview {
  top: -25% !important;
  left: -10% !important;
}



@media (max-width: 520px) {

  .lobby {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .lobby td {
    font-size: .8rem;
  }

  /* mobile and below */

  .select_time_box {
    font-size: 11px;
  }

  .select_time_box>span {
    font-size: 15px;
  }

  .select_main_heading {
    display: -ms-grid;
    display: grid;
  }

  .select_main_heading p {
    padding-bottom: 1rem;
  }

  .market_place_header {
    display: block;
  }

  .market_place_header h5 {
    padding-bottom: 0.7rem;
  }

  .shop-history-marketplace-list img {
    width: 30px;
    height: 30px;
  }

  .shop-history-marketplace-list {
    font-size: 12px;
  }
}

/* ====================================================================== */
@media (max-width: 991.98px) {
  .offcanvas-collapse {
    position: fixed;
    top: 56px;
    /* Height of navbar */
    bottom: 0;
    left: 100%;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: #343a40;
    -webkit-transition: visibility 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    transition: visibility 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    -o-transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  }

  .offcanvas-collapse.open {
    visibility: visible;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  .moveHistory {
    font-size: .7rem;
    font-weight: 100 !important;
  }
}

.moveHistory {
  scroll-padding: 20px;
}




@keyframes fadeInSlide {
  from {
    transform: translateY(10px);
    opacity: .7;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}


.game_in_progress_inner {
  color: #fff;
}





.wallet-text-2 {
  display: none !important;
}


@media screen and (max-width : 1200px) {
  .menu-icon {
    display: block;
  }
}

@media screen and (max-width : 990px) {
  .game_in_progress {
    margin-top: 1rem;
  }

  .waiting-popup {
    top: 0;
    right: 0;
  }

  .aside-left,
  .aside-right {
    width: 90%;
    height: auto;
    background-color: transparent;
    border: none;
  }

  .power-container-sm {
    display: flex !important;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .power-container-sm>.powers {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .power-container-lg {
    display: none;
  }


  .section-top {
    display: none;
  }

  .eternl-desktop {
    display: none !important;
  }

  .wallet-text-2 {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    padding-left: 1.5rem !important;
  }

  .section-top-2 {
    display: block;
  }

  .chessboard-container {
    width: 800px;
    height: 800px;
  }

  .time-mobile-450 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .board_countdown {
    font-size: .8rem;
  }

  .hightlight {
    background: #383636;
    font-size: .8rem;
    padding: 0 0.8rem;
    border-radius: 10px;
    border: 1px solid rgb(200, 169, 12);
  }

  .player-1,
  .player-2 {
    font-size: .8rem;
    color: rgb(255, 255, 255);
  }

  .chessRightBox {
    height: 0px;
  }

  .msg_chessboard_Box p {
    top: 17%;
  }

  .preview {
    top: -25% !important;
    left: -25% !important;
  }
}



@media (max-width: 768px) {



  .playing-player {
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    font-size: .6rem;
  }

  /* tablet and below */
  .market_place_sidebar {
    height: auto;
  }

  #wallet-dropdown {
    right: 37%;
    top: 12%;
  }

  .selectbuttonActive {
    font-size: 1rem;
  }

  .dropdown-menu {
    -webkit-transform: translate(10px, -42px) !important;
    -ms-transform: translate(10px, -42px) !important;
    transform: translate(10px, -42px) !important;
    border-radius: 10px;
  }

  .select_Game_Type {
    margin-top: 2rem;
  }

  .promotion-piece {
    height: 70px;
  }

  .finishedTournamentContainer {
    scale: .8;
  }

  .app-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 0px;
  }

  .chessboard-container {
    height: 600px;
    width: 600px;
  }

  .chessRightBox {
    height: 0px;
  }

  .vertical-text {
    -webkit-writing-mode: horizontal-tb;
    -ms-writing-mode: lr-tb;
    writing-mode: horizontal-tb;
  }

  .grey_container {
    height: auto;
  }

  .playercontainer {
    margin: .9rem;
    flex-direction: column;
    height: auto;
    padding: 0 auto;
  }



}


@media (max-width: 576px) {

  .reason,
  .winner {
    font-size: 10px !important;
    color: rgb(147, 147, 147);
    font-weight: 100 !important;
  }


  .select_main_heading p {
    font-size: large;
  }

  .chessboard-container {
    height: 400px;
    width: 400px;
  }

  .join_room_class {
    width: 90%;
  }

  .game-data span {
    font-size: .8rem;
  }

  .copy_url_div {
    font-size: .6rem;
  }

  .msg_chessboard_Box p {
    font-size: .6rem;
    top: 17%;
  }

  .powerup_container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    padding: 1rem;
  }

  .selectTab {
    width: 100%;
    margin-left: 50px;
  }
}




@media screen and (max-width: 428px) {
  .playing-player {
    flex-direction: column;
    align-items: center;
    font-size: .6rem;
    gap: 0px;
  }

  .game-message span {
    font-size: 0.6rem !important;
  }

  .playercontainer {
    font-size: 10px;
  }

  .waiting-popup p,
  .waiting-popup span {
    font-size: .7rem !important;
    text-align: left;
  }

  .waiting-popup {
    height: 9rem;
  }

  .chessboard-container {
    height: 390px;
    width: 390px;
  }

  .copy_url_div {
    font-size: .4rem;
  }

  .board_countdown-mobile {
    font-size: 9px !important;
  }

  .copy_url_div span {
    font-size: .5rem;
  }

  .powerup_container {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    padding: 1rem;
  }

  .playercontainer .creator,
  .playercontainer .user_name {
    font-size: .6rem !important;
  }

  .tournament_list_container .tournament_name,
  .tournament_list_container .mode {
    font-size: 1rem;
  }

  .tournamnet_time_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .tournamnet_time_container p {
    font-size: .7rem !important;
  }

  .timer span {
    font-size: .8rem !important;
  }

  .join-button {
    padding: .5rem !important;
  }

  .join-button span {
    font-size: 10px !important;
  }


  #wallet-dropdown {
    right: 22%;
    top: 12%;
  }

  .level-slider {
    padding-inline: .5rem !important;
  }

  .detail-lg {
    display: none !important;
  }

  .detail-sm {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .listContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /* flex-direction: column; */
    width: 100%;
    margin: auto;
  }

  .game-icon {
    height: 2rem !important;
  }

  .listContainer h5 {
    font-size: .9rem;
  }


  .preview {
    top: -75% !important;
    left: -55% !important;
  }

   .preview >img {
     width: 100%;
   }
  

  .promotion-piece {
    height: 43px;
  }

  .finishedTournamentContainer {
    scale: .7;
  }

  .playercontainer h5 {
    font-size: 12px !important;
  }

  .selectTab {
    width: 100%;
    margin-left: 50px;
  }

  .swiss-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
  }



}


@media (hover: none) {
  .select_time_box:hover {
    background: #5a5a5a;
    border: 1px solid #464646e9;
  }

  .select_piece_img:hover {
    /* background: #3c3e42; */
    background: rgb(117, 117, 117);
    background: -o-radial-gradient(circle, rgba(117, 117, 117, 1) 1%, rgba(60, 62, 66, 1) 27%);
    background: radial-gradient(circle, rgba(117, 117, 117, 1) 1%, rgba(60, 62, 66, 1) 27%);
    border: 1px solid #5a5a5a;
  }

  .nav-item:hover {
    background: transparent;
  }

  .nav-item>a:hover {
    color: inherit;
  }
}

.toast {
  position: absolute;
  right: 0%;
  top: 101%;
  border: 1px solid #353535;
}

.toast-header .btn-close {
  display: none;
}



@media screen and (max-width: 375px) {

  .chessboard-container {
    height: 300px;
    width: 300px;
  }

  .preview {
    top: -6% !important;
    left: -14% !important;
  }



  .promotion-piece {
    height: 32px;
  }
}

body.modal-open {
  height: 100vh;
  overflow-y: hidden !important;
}

/* new chess styling */
.main-logo::before {
  content: "";
  width: 100%;
  height: 50%;
  max-width: 20rem;
  max-height: 20rem;
  background-color: rgba(97, 97, 97, 0.918);
  position: absolute;
  border-radius: 50%;
  z-index: -1;
  filter: blur(60px);
}


.splash-loading-bg {
  background: rgb(48, 48, 48);
  background: linear-gradient(180deg, rgba(48, 48, 48, 1) 13%, rgba(255, 255, 255, 1) 100%);
}


.bordered-text {
  text-shadow: 1px 1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000, -1px -1px 0 #000;
}

input[type="range"] {
  padding: 0rem !important;
}

/* range slider */
.setting-range-input {
  padding: 0 !important;
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 15px;
  height: 15px;
  border: 2.5px solid #c9c9c9;

  background: linear-gradient(90deg, rgb(46, 46, 46) 0%, rgb(46, 46, 46) 100%);
  /* overflow: hidden; */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.setting-range-input::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 25px;
  width: 25px;
  background: #2c2c2b;
  border-radius: 50%;
  border: 1px solid #fff;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}



.setting-range-input::-moz-range-thumb {
  /* -webkit-appearance: none; */
  height: 25px;
  width: 25px;
  background-color: rgb(73, 73, 73);
  border-radius: 50%;
  border: none;
  -moz-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}


.game-modes {
  animation: gameMode .4s cubic-bezier(.32, .87, 0, 1.56) both;
}


.chess_logo_animate  {
  animation: gameMode .4s cubic-bezier(.32, .87, 0, 1.56) backwards;
}

@keyframes gameMode {
  from {
    scale: .3;
    opacity: .3;
  }

  to {
    scale: 1;
    opacity: 1;
  }
}

.game-modes:nth-child(2) {
  animation-delay: .1s;
}

.game-modes:nth-child(3) {
  animation-delay: .2s;
}

.private-room-card  {
   animation: fadeIn .2s ease-in;
}

@keyframes fadeIn {
  from {
    transform: translateY(-100px);
    opacity: .3;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

.gameModeContainer {
   overflow: hidden;
}


.gameModeContainer::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -2;
  background: linear-gradient(90deg, transparent, #9b4fff, #ffffff, #ba66ff00, #ff996600, transparent);
}

.gameModeContainer:hover::after {
  animation: rotation_481 5000ms infinite linear both;
}


@keyframes rotation_481 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.main-logo-container{
  height : 20rem;
}

.main-logo { 
  width : 20rem;
}

@media screen and (max-height : 600px) {
  .main-logo-container{
     height : 10rem;
  }
   .main-logo { 
      width : 12rem;
   }
}