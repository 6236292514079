/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.app {
  height: 100dvh;
}


:root {
  --start-w: 300px;
  --start-h: 300px;
  --default-home: 12rem;
  --start-circle-w: 3rem;
  --board-size: 750px;
  --cell-size: 50px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* h2 {
  color: #212121;
} */

.counter-button  {
   outline: 2px solid rgba(255, 179, 39, 0.801);
   border-radius: 10px !important;
}

button {
  padding: 10px;
  margin-right: 5px;
  cursor: pointer;
  border-radius: 5%;
  color: white;
  border: 1px solid #212121;
  font-weight: 600;
}

button[disabled] {
  opacity: 0.5;
}

.btn-primary {
  background-color: #388e3c;
}

.btn-secondary {
  background-color: #d32f2f;
}

.board {
  position: relative;
  width: var(--board-size);
  height: var(--board-size);
  min-width: var(--board-size);
  max-height: var(--board-size);
  border-radius: 35px;
}

.board-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100dvh;
  -webkit-box-flex: 0.52;
      -ms-flex: 0.52;
          flex: 0.52;
}



.cell {
  display: -ms-grid;
  display: grid;
  place-items: center;
  width: var(--cell-size);
  height: var(--cell-size);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  z-index: 2;
}

.cell-nature-white:nth-child(even) {
  background: rgb(199 231 236) !important;
  border-radius: 5px;
}


.shadow-inner {
  -webkit-box-shadow: inset 2px 2px 2px #d3d3d3c2,
    inset -1px -1px 1px #5f5f5f;
          box-shadow: inset 2px 2px 2px #d3d3d3c2,
    inset -1px -1px 1px #5f5f5f;
  border-radius: 4px;
  /* scale: 1 !important; */
}

.cell--station {
  font-size: 24px;
  color: grey;
  display: -ms-grid;
  display: grid;
  place-items: center;
}

.cell--station>img {
  margin-top: .2rem;
  scale: .8;
}



.dice {

  margin: 0 .55rem .75rem .75rem;
  border-radius: 5%;
  /* background-color: #cfd8dc; */
  /* box-shadow: 0px 0px 15px 0px #212121; */
  display: -ms-grid;
  display: grid;
  background: rgb(247,231,231);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(247,231,231,1)), to(rgba(223,165,165,1)));
  background: -o-linear-gradient(top, rgba(247,231,231,1) 0%, rgba(223,165,165,1) 100%);
  background: linear-gradient(180deg, rgba(247,231,231,1) 0%, rgba(223,165,165,1) 100%);
}


.dice button {
  border: none;
  outline: none;
  -webkit-perspective: none;
          perspective: none;
}


.start {
  position: absolute;
  width: var(--start-w);
  height: var(--start-h);

}



.start .green-home,
.start .red-home,
.start .yellow-home,
.start .blue-home {
  position: absolute;
  width: var(--default-home);
  height: var(--default-home);
  background-color: #fff !important;
  left: 50% !important;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-box-shadow: -1px -1px 47px 21px rgba(38, 38, 38, 0.203);
  box-shadow: -1px -1px 47px 21px rgba(38, 38, 38, 0.203);
}

.start .green-nature-home,
.start .red-nature-home,
.start .yellow-nature-home,
.start .blue-nature-home {
  position: absolute;
  width: 13rem;
  height: 13rem;
  /* background-color: #fff !important; */
  left: 50% !important;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);

}

.start .green-home {
  border-radius: 2.5rem 2.5rem 2.5rem 0rem;
}

.start .green-nature-home {
  background: -o-linear-gradient(113deg, rgb(199, 255, 167) 0%, rgb(77, 187, 26) 37%, rgb(86, 206, 30) 58%, rgb(170, 255, 121) 95%);
  background: linear-gradient(337deg, rgb(199, 255, 167) 0%, rgb(77, 187, 26) 37%, rgb(86, 206, 30) 58%, rgb(170, 255, 121) 95%);
  border-radius: 1.5rem;
  -webkit-box-shadow: 0px 2px 2px 10px #34b61e;
          box-shadow: 0px 2px 2px 10px #34b61e;
  -webkit-filter: drop-shadow(2px 2px 5px rgba(59, 59, 59, 0.664));
          filter: drop-shadow(2px 2px 5px rgba(59, 59, 59, 0.664));
}

.start .red-home {
  border-radius: 0 2.5rem 2.5rem 2.5rem;
}

.start .red-nature-home {
  background: -o-linear-gradient(113deg, rgba(255, 128, 131, 1) 0%, rgba(247, 51, 52, 1) 37%, rgba(250, 56, 56, 1) 58%, rgba(255, 142, 144, 1) 95%);
  background: linear-gradient(337deg, rgba(255, 128, 131, 1) 0%, rgba(247, 51, 52, 1) 37%, rgba(250, 56, 56, 1) 58%, rgba(255, 142, 144, 1) 95%);
  border-radius: 1.5rem;
  -webkit-box-shadow: 0px 0px 2px 10px #e03a3a;
          box-shadow: 0px 0px 2px 10px #e03a3a;
  -webkit-filter: drop-shadow(2px 2px 5px rgba(59, 59, 59, 0.664));
          filter: drop-shadow(2px 2px 5px rgba(59, 59, 59, 0.664));
}

.start .blue-home {
  border-radius: 2.5rem 2.5rem 0rem 2.5rem;
}

.start .blue-nature-home {
  background: -o-linear-gradient(113deg, #44b7ff 0%, #009cff 37%, #009cff 58%, #44b7ff 95%);
  background: linear-gradient(337deg, #44b7ff 0%, #009cff 37%, #009cff 58%, #44b7ff 95%);
  border-radius: 1.5rem;
  -webkit-box-shadow: 0px 0px 2px 10px #009cff;
          box-shadow: 0px 0px 2px 10px #009cff;
  -webkit-filter: drop-shadow(2px 2px 5px rgba(59, 59, 59, 0.664));
          filter: drop-shadow(2px 2px 5px rgba(59, 59, 59, 0.664));
}


.start .yellow-home {
  border-radius: 2.5rem 0 2.5rem 2.5rem;
}

.start .yellow-nature-home {
  background: -o-linear-gradient(113deg, rgb(255, 243, 209) 0%, #ffcc00 37%, #ffcc00 58%, rgb(255, 226, 164) 95%);
  background: linear-gradient(337deg, rgb(255, 243, 209) 0%, #ffcc00 37%, #ffcc00 58%, rgb(255, 226, 164) 95%);
  border-radius: 1.5rem;
  -webkit-box-shadow: 0px 0px 2px 10px #ffcc00;
          box-shadow: 0px 0px 2px 10px #ffcc00;
  -webkit-filter: drop-shadow(2px 2px 5px rgba(59, 59, 59, 0.664));
          filter: drop-shadow(2px 2px 5px rgba(59, 59, 59, 0.664));
}




.start-circle {
  width: 35px;
  height: 35px;
}

.start+.green-safe {
  border-top-left-radius: 35px;
}

.start+.yellow-safe {
  border-top-right-radius: 35px;
}

.red-safe:not(.cell+.red-safe) {
  border-bottom-left-radius: 35px;
}

.start+.blue-safe {
  border-bottom-right-radius: 35px;
}

.green-safe .start-circle,
.red-safe .start-circle,
.yellow-safe .start-circle,
.blue-safe .start-circle {
  width: var(--start-circle-w);
  height: var(--start-circle-w);
  position: absolute;
  border-radius: 50%;
}

.green-safe .start-circle {
  background-color: #439921;
  -webkit-box-shadow: inset 5px 5px 10px #1e7e06,
    inset -5px -5px 10px #0f990f;
          box-shadow: inset 5px 5px 10px #1e7e06,
    inset -5px -5px 10px #0f990f;
}

.yellow-safe .start-circle {
  background-color: #dba423;
  -webkit-box-shadow: inset 5px 5px 10px #ac7f16,
    inset -5px -5px 10px #b4871d;
          box-shadow: inset 5px 5px 10px #ac7f16,
    inset -5px -5px 10px #b4871d;
}

.blue-safe .start-circle {
  background-color: #1c47d3c2;
  -webkit-box-shadow: inset 5px 5px 10px #074386,
    inset -5px -5px 10px #053e6d;
          box-shadow: inset 5px 5px 10px #074386,
    inset -5px -5px 10px #053e6d;
}

.red-safe .start-circle {
  background-color: #c72020;
  -webkit-box-shadow: inset 5px 5px 10px #9c1705,
    inset -5px -5px 10px #7416057c;
          box-shadow: inset 5px 5px 10px #9c1705,
    inset -5px -5px 10px #7416057c;
}



/* green token */
.green-safe .start-circle:nth-child(1),
.yellow-safe .start-circle:nth-child(1),
.red-safe .start-circle:nth-child(1),
.blue-safe .start-circle:nth-child(1) {
  left: 28%;
  top: 28%;
  z-index: 2;
}

.green-safe .start-circle:nth-child(2),
.yellow-safe .start-circle:nth-child(2),
.red-safe .start-circle:nth-child(2),
.blue-safe .start-circle:nth-child(2) {
  left: 58%;
  top: 28%;
  z-index: 2;
}

.green-safe .start-circle:nth-child(3),
.yellow-safe .start-circle:nth-child(3),
.red-safe .start-circle:nth-child(3),
.blue-safe .start-circle:nth-child(3) {
  left: 28%;
  top: 58%;
  z-index: 2;
}

.green-safe .start-circle:nth-child(4),
.yellow-safe .start-circle:nth-child(4),
.red-safe .start-circle:nth-child(4),
.blue-safe .start-circle:nth-child(4) {
  left: 58%;
  top: 58%;
  z-index: 2;
}

/* yellow token */







.token {
  position: absolute;
  /* width: 45px;
  height: 45px; */
  z-index: 50;
  height: 50px;
  width: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  place-items: center;
  /* border-radius: 50%;
  border: 2px solid #212121; */
  /* transition: all 1s ease-in;
  box-shadow: 0px 0px 5px 0px #212121; */
}

/* .token-gear::after {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: rgba(240, 240, 240, 0);
  border: 6px dotted rgb(1, 238, 255);
  border-radius: 50px;
  top: 44%;
  left: 10%;
  z-index: -2;
  perspective: 500px;
  transform: rotateX(46deg) rotateY(0deg);
  transform-style: preserve-3d;
  animation: rotation 1s linear infinite;
} */

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.red-safe {
  background-color: #f44336;
}

.yellow-safe {
  background-color: #ffc107;
  background: url('./assets/tiles/yellowTile.png');
}

.green-safe {
  background-color: #4caf50;
  background: url('./assets/tiles/greenTile.png');
  /* background-size: contain; */
  /* background-repeat: inherit; */
}

.blue-safe {
  background-color: #2196f3;
  background: url('./assets/tiles/blueTile.png');
}


.yellow-token--active {
  /* border: 2px solid white; */
  z-index: 100;
}



.green-token--active {
  /* border: 2px solid white; */
  z-index: 100;
}

.ract {
  -webkit-box-shadow: 0px 0px 10px 10px rgba(39, 39, 39, 0.26);
          box-shadow: 0px 0px 10px 10px rgba(39, 39, 39, 0.26);
  background: rgb(255, 255, 255);
}

[cell-id *="20"]>.cell--station,
[cell-id *="39"]>.cell--station,
[cell-id *="58"]>.cell--station,
[cell-id *="1"]>.cell--station {
  display: none;
}


.blue-token--active {
  /* border: 2px solid white; */
  z-index: 100;
}

.blink {
  -webkit-animation: box-blinker .4s linear infinite alternate;
          animation: box-blinker .4s linear infinite alternate;
}

.blink_token {
  -webkit-animation: blink_token .4s linear infinite alternate;
          animation: blink_token .4s linear infinite alternate;
  -webkit-filter: drop-shadow(0px 0px 3px black);
          filter: drop-shadow(0px 0px 3px black);
  border-radius: 50%;
  /* border: 3px dashed rgb(56, 56, 56);  */
}



/* .blink_token::after {
   content: "";
  width: 50px;
  height: 50px;
  position: absolute;
  border: 3px dashed black; 
  border-radius: 50%;
}  */

@-webkit-keyframes box-blinker {
  from {
    -webkit-opacity: 0.4;
    opacity: 0.4;
  } to {
    -webkit-opacity: 1;
    opacity: 1;
  }
}

@keyframes box-blinker {
  from {
    -webkit-opacity: 0.4;
    opacity: 0.4;
  } to {
    -webkit-opacity: 1;
    opacity: 1;
  }
}

@-webkit-keyframes blink_token {
  from {
     scale: .8;
     opacity: 0.8
  }
  to {
    scale: 1.03;
    opacity: 1.1;
  }
}
@keyframes blink_token {
  from {
     scale: .8;
     opacity: 0.8
  }
  to {
    scale: 1.03;
    opacity: 1.1;
  }
}

.arrow-up {
  width: 0;
  height: 0;
  background: transparent;
  border-left: 74px solid transparent;
  border-right: 75px solid transparent;
  border-bottom: 77px solid #e61111;
}

.arrow-up-default {
  width: 0;
  height: 0;
  background: transparent;

}

.arrow-right {
  width: 0;
  height: 0;
  background: transparent;
  border-top: 75px solid transparent;
  border-bottom: 75px solid transparent;
  border-left: 75px solid #37c320;
}

.arrow-down {
  width: 0;
  height: 0;
  background: transparent;
  border-left: 75px solid transparent;
  border-right: 75px solid transparent;
  border-top: 75px solid #ffc107;
}

.arrow-left {
  width: 0;
  height: 0;
  background: transparent;
  border-top: 75px solid transparent;
  border-bottom: 75px solid transparent;
  border-right: 75px solid #2196f3;
}

.arrow-blue>.arrows {
  width: 150px;
  top: -72px;
  left: 4px;
  width: 4.5rem;
}

.arrow-red>.arrows {
  width: 150px;
  top: -63px;
  left: -2px;
  /* width: 9.5rem; */
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}

.arrow-green>.arrows {
  width: 150px;
  top: -73px;
  left: -75px;
  width: 4.6rem;
  /* z-index: 5; */
}

.arrow-yellow>.arrows {
  width: 150px;
  top: -65px;
  left: -76px;
  -webkit-transform: rotateX(176deg);
          transform: rotateX(176deg);
  /* z-index: 5; */
}


/* plates */
.red-plate,
.yellow-plate {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}


.playerBox {
  background-image: conic-gradient(red 0deg, rgba(213, 186, 186, 0.308) 0deg);
  padding: .15rem;
}

/* custom modal size */
.rodal-dialog {
  border-radius: 10px !important;
  outline: 4px solid yellow;
}

.button-shadow {
  -webkit-box-shadow: 0px 0px 5px 3px #ffc831d5 !important;
          box-shadow: 0px 0px 5px 3px #ffc831d5 !important;
  outline: 2px solid rgb(189, 117, 8);
  border: none;
}

.card-wrapper,
.rodal-dialog {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(17%, rgba(21, 3, 117, 1)), color-stop(92%, rgba(78, 61, 234, 1))) !important;
  background: -o-linear-gradient(top, rgba(21, 3, 117, 1) 17%, rgba(78, 61, 234, 1) 92%) !important;
  background: linear-gradient(180deg, rgba(21, 3, 117, 1) 17%, rgba(78, 61, 234, 1) 92%) !important;
  -webkit-box-shadow: 0px 0px 5px 3px #ffbb00d2 !important;
          box-shadow: 0px 0px 5px 3px #ffbb00d2 !important;
  outline: 2px solid yellow;
  border-radius: 5px;
}

.text-wrapper {
  -webkit-box-shadow: 0px 0px 8px 3px #ffbb00d2 !important;
          box-shadow: 0px 0px 8px 3px #ffbb00d2 !important;
}

.counter-card-inner {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(17%, rgba(21, 3, 117, 1)), color-stop(92%, rgba(78, 61, 234, 1))) !important;
  background: -o-linear-gradient(top, rgba(21, 3, 117, 1) 17%, rgba(78, 61, 234, 1) 92%) !important;
  background: linear-gradient(180deg, rgba(21, 3, 117, 1) 17%, rgba(78, 61, 234, 1) 92%) !important;
}


.rodal-close {
  display: none;
}

/* counter card */
.counter-card {
  -webkit-box-shadow: inset 0px 0px 46px 0px rgba(255, 255, 112, 1);
  box-shadow: inset 0px 0px 46px 0px rgba(255, 255, 112, 1);
  -webkit-filter: drop-shadow(0px 0px 12px #ffce47);
          filter: drop-shadow(0px 0px 12px #ffce47);
}

.play {
  -webkit-box-shadow: inset 0px 0px 46px 0px rgba(255, 255, 112, 1);
  box-shadow: inset 0px 0px 14px 0px rgb(255 255 97);
  -webkit-filter: drop-shadow(0px 0px 12px #ffce4761);
          filter: drop-shadow(0px 0px 12px #ffce4761);

}

/* radio button css */

.animate {
  -webkit-animation: scaleDown .1s ease-in;
          animation: scaleDown .1s ease-in;
}

#setting-modal input[type="range"] { 
  padding: 0rem !important;
}

/* range slider */
.range-input {
  padding: 0 !important;
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 15px;
  height: 15px;
  border: 2.5px solid #ffd05a;
  background: -webkit-gradient(linear, left top, right top, from(hsla(225, 89%, 47%, 1)), to(hsla(225, 89%, 47%, 1)));
  background: -o-linear-gradient(left, hsla(225, 89%, 47%, 1) 0%, hsla(225, 89%, 47%, 1) 100%);
  background: linear-gradient(90deg, hsla(225, 89%, 47%, 1) 0%, hsla(225, 89%, 47%, 1) 100%);
  /* overflow: hidden; */
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.range-input::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 25px;
  width: 25px;
  background: #ffc422;
  border-radius: 50%;
  border: 1px solid #fff;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}



.range-input::-moz-range-thumb {
  /* -webkit-appearance: none; */
  height: 25px;
  width: 25px;
  background-color: #f50;
  border-radius: 50%;
  border: none;
  -moz-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}


/* nature asset */
.nature-start-fish {
  position: absolute;
  z-index: 1;
  left: 27%;
  top: 56.6%;
}

.nature-shell-fish {
  position: absolute;
  z-index: 1;
  right: 30%;
  top: 30%;
}


.nature-leaf-top {
  position: absolute;
  z-index: 1;
  top: 1%;
  left: 1.5%;
  width: 45%;
}

.nature-leaf-top img {
  border-top-left-radius: 1.5rem;
}

.nature-leaf-right {
  position: absolute;
  z-index: 1;
  top: 1%;
  right: 1.5%;
  width: 45%;
}

.nature-leaf-right img {
  border-top-right-radius: 1.5rem;
}

.nature-leaf-left {
  position: absolute;
  z-index: 1;
  bottom: 1%;
  left: 1.5%;
  width: 45%;
}

.nature-leaf-left img {
  border-bottom-left-radius: 1.5rem;
}

.nature-leaf-bottom {
  position: absolute;
  z-index: 1;
  bottom: 1%;
  right: 1.5%;
  width: 45%;
}

.nature-leaf-bottom img {
  border-bottom-right-radius: 1.5rem;
}

.arrow {
  position: absolute;
  z-index: 2;
}

.arrow.left {
  top: 35%;
  left: 35%;
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
  scale: .7;
}

.arrow.right {
  top: 35%;
  right: 35%;
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
  scale: .7;
}

.arrow.top {
  bottom: 35%;
  left: 35%;
  -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
          transform: rotate(0deg);
  scale: .7;
}

.arrow.bottom {
  bottom: 35%;
  right: 35%;
  -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
  scale: .7;
}


/* bend arrow */
.bend-top {
  top: 0%;
  left: 41%;
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
  scale: .7;
}

.bend-right {
  top: 41.5%;
  right: 0%;
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
  scale: .7;
}

.bend-left {
  bottom: 41.5%;
  left: 0%;
  scale: .7;
}

.bend-bottom {
  bottom: 1.5%;
  right: 41%;
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg);
  scale: .7;
}

.text-shadow {
  text-shadow: 2px 0 #0000008a, -2px 0 #00000086, 0 2px #0000008a, 0 -2px #0000008a,
    1px 1px #0000008a, -1px -1px #0000008a, 1px -1px #0000008a, -1px 1px #0000008a;
  color: #fff;
}


.setting {
  position: absolute;
  bottom: 12%;
  left: 20%;
  -webkit-perspective: 500px;
          perspective: 500px;
  -webkit-transform: rotateX(-36deg) rotateY(0deg);
          transform: rotateX(-36deg) rotateY(0deg);
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  z-index: 2;
}

.gameStartImage {
   -webkit-animation: scaleUp .3s cubic-bezier(.47,1.64,.41,.8);
           animation: scaleUp .3s cubic-bezier(.47,1.64,.41,.8);
}


@-webkit-keyframes scaleUp {
   from {
      -webkit-transform: scale(.6);
              transform: scale(.6);
      } to {
       -webkit-transform: scale(1);
               transform: scale(1);
   }
}


@keyframes scaleUp {
   from {
      -webkit-transform: scale(.6);
              transform: scale(.6);
      } to {
       -webkit-transform: scale(1);
               transform: scale(1);
   }
}


@-webkit-keyframes scaleDown {
  from {
    scale: 0;
  }

  to {
    scale: 1;
  }
}


@keyframes scaleDown {
  from {
    scale: 0;
  }

  to {
    scale: 1;
  }
}

.rodal-mask {
  position: relative;
}

/* menu-modal */

.players-wrapper-box {
   width: auto;
   height: auto;
}

/* ._space3d  {
    
} */
#win-loss-modal {
  min-height: 23rem !important;
}

._space3d { 
  margin: auto !important;
}




/* join-room-modal */
@media screen and (min-width: 300px) {
  #conformation-modal {
    width: 90% !important;
    height: 30% !important;
  }

  #join-room-modal {
    width: 95% !important;
    height: 70% !important;
  }
}


@media screen and (min-width: 320px) {
 
 
  #profile-modal {
     width: 70% !important;
    max-height: 60% !important;
 }
  
  #conformation-modal {
    width: 90% !important;
    max-width: 20rem;
  }

  #join-private-room-modal {
    max-width: 25rem !important;
    max-height: 17rem !important;
  }

  #select-game-modal {
    width: 90% !important;
    height: 95% !important;
  }

  #win-loss-modal {
    width: 95% !important;
    height: 50% !important;
  }

  #profile-modal {
    width: 95% !important;
    height: 70% !important;
  }

  #menu-modal {
    width: 70% !important;
    height: 20%;
  }

  #join-room-modal {
    max-width: 22rem;
    max-height: 27rem !important;
  }

  #setting-modal {
    width: 90% !important;
    /* height: 50% !important; */
    min-height: 25rem !important;
  }

  #select-game-type-modal {
    width: 90% !important;
    height: 20%;
    min-height: 40% !important;
  }

  #private-room-player-modal,
  #Themes {
    width: 90% !important;
    height: 95% !important;
  }

  .board-container {
    scale: 1;
  }

  #create-room-modal {
    width: 90% !important;
    height: 45% !important;
  }

  #select-pawn-modal,
  #rematch-modal {
    width: 70% !important;
    max-width: 20rem !important;
    max-height: 18rem !important;
  }

  #menu-modal {
    max-width: 20rem !important;
    height: 18rem !important;
  }
   
  #Themes {
    max-width: 27rem !important;
    /* max-height: 100% !important; */
    /* min-height: 41rem !important; */
  }

  #Themes .card-wrapper {
     width: auto;
     /* max-height: 1rem; */
     -webkit-box-shadow: none !important;
             box-shadow: none !important;
     border: none !important;
     /* outline: none; */
  }

  #Themes .select-button  {
      margin-top: 0;
  }

  #select-game-modal .counter-text {
     /* width: 100%; */
     font-size: 1rem !important
  }


  .board-container {
    height: auto;
  }
}

@media screen and (min-width: 425px) {
     #profile-modal {
    max-width: 60% !important;
    max-height: 50% !important;
 }

  #conformation-modal {
    width: 70% !important;
    height: 25% !important;
  }

  #setting-modal {
    width: 75% !important;
    height: 50% !important;
  }

  #select-game-modal .card-wrapper {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(17%, rgba(20, 3, 117, 0)), color-stop(92%, rgba(78, 61, 234, 0))) !important;
    background: -o-linear-gradient(top, rgba(20, 3, 117, 0) 17%, rgba(78, 61, 234, 0) 92%) !important;
    background: linear-gradient(180deg, rgba(20, 3, 117, 0) 17%, rgba(78, 61, 234, 0) 92%) !important;
    -webkit-box-shadow: 0px 0px 5px 3px #ffbb0000 !important;
            box-shadow: 0px 0px 5px 3px #ffbb0000 !important;
    outline: 2px solid rgba(255, 255, 0, 0);
    border-radius: 5px;
  }

  #profile-modal,
  #win-loss-modal {
    width: 95% !important;
    height: 60% !important;
  }

  #select-game-type-modal {
    width: 90% !important;
    height: 20%;
    min-height: 40% !important;
  }

}

@media screen and (min-width : 600px) {
 

  #conformation-modal {
    width: 90% !important;
    max-height: 12rem !important;
    max-width: 22rem !important;
  }

  #select-game-type-modal {
    max-width: 21rem;
    max-height: 18rem !important;
  }

  #create-room-modal {
    max-width: 27rem;
    max-height: 21rem !important;
  }

  #setting-modal {
    /* height: 50% !important; */
    max-width: 20rem;
    max-height: 20rem !important;
  }

  #select-game-modal {
    max-width: 28rem !important;
    max-height: 64% !important;
  }

  #join-room-modal {
    max-width: 25rem;
    max-height: 32rem !important;
  }

  #menu-modal {
    max-width: 20rem !important;
    max-height: 18rem !important;
  }

  #select-pawn-modal {
    width: 70% !important;
    max-width: 25rem !important;
    max-height: 16rem !important;
  }


  #Themes {
    max-width: 29rem !important;
    max-height: 42rem !important;
  }

  #private-room-player-modal {
    max-width: 25rem;
    max-height: 34rem !important;
  }

}


@media screen and (min-width: 768px) {
  #select-price-modal {
    min-width: 25rem !important;
    min-height: 20rem !important;
  }

  #conformation-modal {
    width: 50% !important;
    height: 20% !important;
  }

  #setting-modal {
    width: 50% !important;
    height: 50% !important;
  }

  #select-game-modal {
    max-width: 100% !important;
    max-height: auto !important;
    width: 65% !important;
    height: 90% !important;
  }

  #Themes {
    width: 65% !important;
    height: 90% !important;
    max-width: 65% !important;
    max-height: 65% !important;
  }

  #profile-modal,
  #win-loss-modal {
    width: 65% !important;
    height: 50% !important;
  }

  #join-room-modal {
    width: 80% !important;
    height: 70% !important;
  }

  #menu-modal {
    width: 40% !important;
    height: 30% !important;
  }

  #select-game-type-modal {
    max-width: 100% !important;
    width: 55% !important;
    height: 65% !important;
    min-height: 20rem !important;
  }

  #private-room-player-modal {
    width: 60% !important;
    height: 70% !important;
  }


  #select-pawn-modal {
    width: 40% !important;
    height: 40% !important;
    max-height: 18rem !important;
  }

  #create-room-modal {
    width: 80% !important;
    height: 50% !important;
    min-width: 40rem !important;
    min-height: 26rem !important;
  }

  #setting-modal {
    min-width: 25rem !important;
  }

  

}

@media screen and (min-width: 1024px) and (max-height : 624px) {
  .Players {
     width: 100% !important;
  }
}

@media screen and (min-width: 1024px) {
  #conformation-modal {
    width: 30% !important;
    height: 25% !important;
  }

  #setting-modal {
    width: 35% !important;
    height: 50% !important;
  }

  #select-pawn-modal {
    width: 25% !important;
    height: 35% !important;
  }

  #create-room-modal {
    width: 35% !important;
    height: 50%;
  }

  #join-room-modal {
    width: 30% !important;
    height: 70%;
  }

  #Themes {
    width: 60% !important;
    height: 70% !important;
  }

  #win-loss-modal {
    width: 45% !important;
    height: 50% !important;
  }

  #profile-modal {
    width: 25% !important;
    height: 50% !important;
  }

  #menu-modal {
    width: 25% !important;
    height: 30% !important;
  }

  #select-game-type-modal {
    width: 30% !important;
    height: 70%;
  }

  #private-room-player-modal {
    width: 40% !important;
    height: 70% !important;
  }

  #select-game-modal {
    width: 80% !important;
    height: 80% !important;
  }

  #select-game-modal .card-wrapper {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(17%, rgba(21, 3, 117, 1)), color-stop(92%, rgba(78, 61, 234, 1))) !important;
    background: -o-linear-gradient(top, rgba(21, 3, 117, 1) 17%, rgba(78, 61, 234, 1) 92%) !important;
    background: linear-gradient(180deg, rgba(21, 3, 117, 1) 17%, rgba(78, 61, 234, 1) 92%) !important;
    -webkit-box-shadow: 0px 0px 5px 3px #ffbb00d2 !important;
            box-shadow: 0px 0px 5px 3px #ffbb00d2 !important;
    outline: 2px solid yellow;
    border-radius: 5px;
  }
}

@media screen and (min-width: 1440px) {
  #conformation-modal {
    width: 25% !important;
  }

  #setting-modal {
    width: 30% !important;
    height: 45% !important;
  }

  #select-pawn-modal {
    width: 25% !important;
    height: 35% !important;
  }

  #select-game-modal {
    width: 65% !important;
    height: 85% !important;
  }

  #win-loss-modal {
    width: 30% !important;
    height: 50% !important;
  }

  #menu-modal {
    width: 15% !important;
    height: 30% !important;
  }

  #select-game-type-modal {
    width: 25% !important;
    height: 65%;
  }

  #private-room-player-modal {
    width: 30% !important;
    height: 80% !important;
  }
}

@media screen and (min-width : 1600px) {

  #rematch-modal {
     min-height: 15rem !important;
  }
  #conformation-modal {
    width: 20% !important;
  }

  #setting-modal {
    width: 20% !important;
    height: 45% !important;
  }

  #select-game-modal {
    width: 60% !important;
    height: 70% !important;
  }
}

/** max width */

/* select-game-modal-room-modal */
@media screen and (max-width: 320px) {
  #win-loss-modal {
    width: 95% !important;
    height: 50% !important;
  }

}

/* card wraper */
@media screen and (max-width : 600px) {

  #select-price-modal {
    min-height: 20rem !important;
  }

  #select-game-modal .card-wrapper {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(17%, rgba(20, 3, 117, 0)), color-stop(92%, rgba(78, 61, 234, 0))) !important;
    background: -o-linear-gradient(top, rgba(20, 3, 117, 0) 17%, rgba(78, 61, 234, 0) 92%) !important;
    background: linear-gradient(180deg, rgba(20, 3, 117, 0) 17%, rgba(78, 61, 234, 0) 92%) !important;
    -webkit-box-shadow: 0px 0px 5px 3px #ffbb0000 !important;
            box-shadow: 0px 0px 5px 3px #ffbb0000 !important;
    outline: 2px solid rgba(255, 255, 0, 0);
    border-radius: 5px;
  }

  #join-private-room-modal {
    width: 90% !important;
  }
  

}

@media screen and (max-height : 400px) {
 
  .board-container {
    scale: .5 !important;
    height: 0%;
    position: relative;
    z-index: 10;
  }

  nav {
    height: 3.5rem !important;
  }

  .main-container {
    margin-top: 6.5rem;
  }

  .Players {
    -webkit-transform: translate(0px) !important;
        -ms-transform: translate(0px) !important;
            transform: translate(10px) !important;
    width: 78%;
  }

  .logo {
    height: 0rem !important;
    /* height: 14rem; */
  }

  .logo>img {
    width: 15rem;
  }

  .game-modes-desktop {
    display: none;
    -webkit-transform: translateY(-40px);
        -ms-transform: translateY(-40px);
            transform: translateY(-40px);
  }

  .game-modes-mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  #conformation-modal {
    width: 90% !important;
    max-width: 20rem;
  }

  #join-private-room-modal {
    max-width: 25rem !important;
    max-height: 17rem !important;
  }

  #select-game-modal {
    width: 90% !important;
    height: 95% !important;
    max-width: 22rem !important;
    min-height: 95% !important;
  }

 

  .playerImageBox {
    scale: .9;
  }

  .player-username {
    /* bottom: 20% !important; */
    position: absolute;
    /* left: 0%; */
    bottom: 1%;
    text-align: center;
    font-size: .6rem;
  }

  #select-game-modal .wrapper-container {
    height: 12rem;
  }

  #select-game-modal .counter {
    /* height: 12rem; */
    width: 100%;
  }



  #select-game-modal button {
    -webkit-transform: translateY(-16px);
        -ms-transform: translateY(-16px);
            transform: translateY(-16px);
  }

  #win-loss-modal {
    width: 95% !important;
    height: 50% !important;
  }

  #profile-modal {
    width: 95% !important;
    height: 70% !important;
  }

  #menu-modal {
    width: 70% !important;
    height: 20%;
  }

  #join-room-modal {
    max-width: 22rem;
    max-height: 27rem !important;
  }

  #setting-modal {
    /* width: 50% !important; */
    min-height: 17rem !important;
    min-width: 20rem !important;
  }

  #select-game-type-modal {
    width: 50% !important;
    height: 20% !important;
    min-height: 16.5rem !important;
  }

  #private-room-player-modal,
  #Themes {
    width: 90% !important;
    height: 95% !important;
  }

  .board-container {
    scale: 1;
  }

  #create-room-modal {
    width: 90% !important;
    height: 45% !important;
    min-height: 17rem !important;
    min-width: 15rem !important;
  }

  #create-room-modal .wrapper {
    padding-top: 1rem;
  }

  #create-room-modal button,
  #select-game-modal button {
    font-size: .7rem;
    padding: .25rem 1rem;
    margin-top: .5rem;
  }

  #select-pawn-modal,
  #rematch-modal {
    width: 70% !important;
    max-width: 20rem !important;
    max-height: 18rem !important;
    min-height: 15rem !important;
  }

  #menu-modal {
    max-width: 20rem !important;
    height: 18rem !important;
  }


  #Themes {
    max-width: 22rem !important;
    max-height: 95% !important;
  }

}


@media screen and (min-width : 1280px ) and (max-width : 1440px ) and (max-height : 768px ) {
  .app {
    height: 100dvh;
  }

  .board-container {
    -webkit-transform: scale(.8);
        -ms-transform: scale(.8);
            transform: scale(.8);
    position: relative;
    z-index: 10;
    height: 100% !important;
  }

  .main-container {
    margin-top: 0.5rem;
    height: 100dvh !important;
  }

  .Players {
    -webkit-transform: translate(0px , 20px) !important;
        -ms-transform: translate(0px , 20px) !important;
            transform: translate(0px , 20px) !important;
    width: 10% !important;
  }

  .playerImageBox {
    width: 7rem !important;
    height: 7rem !important;
  }

  .playerImageBox img {
    width: 6.5rem !important;
    height: 6.5rem !important;
  }
  
  .dice {
    width: 4.5rem !important;
    height: 4.5rem !important;
    right: 2%;
    box-sizing: border-box;
    
  }
   
  #select-game-modal { 
     max-width: 80% !important;
     min-height: 70% !important;
  }
  

  #Themes {
    max-width: 80% !important;
  }
}


@media screen and (min-width : 1024px ) and (max-width : 1268px ) {
  .app {
    height: 100dvh;
  }

  .board-container {

    -webkit-transform: scale(.7);
        -ms-transform: scale(.7);
            transform: scale(.7);
    position: relative;
    z-index: 10;
  }

  .main-container {
    margin-top: 0;
    height: 100dvh !important;
  }

  .Players {
    -webkit-transform: translate(0px , 20px) !important;
        -ms-transform: translate(0px , 20px) !important;
            transform: translate(0px , 20px) !important;
    width: 80% !important;
  }

  .playerImageBox {
    width: 4.8rem !important;
    height: 4.8rem !important;
  }

  .playerImageBox img {
    width: 4.5rem !important;
    height: 4.5rem !important;
  }
  
  .dice {
    width: 3.5rem !important;
    height: 3.5rem !important;
    right: 2%;
  }
   
  #select-game-modal { 
     max-width: 80% !important;
  }
  

  #Themes {
    max-width: 80% !important;
  }
}


@media screen and (min-width: 320px) and (max-height : 700px) {
  .join-button  {
    margin-right: .4rem !important;
 }

  .board-container {
    scale: .9 !important;
    height: 0%;
    position: relative;
    z-index: 10;
  }

  .main-container {
    margin-top: 6.5rem;
  }

 

  .Players {
    -webkit-transform: translate(0px , 40px) !important;
        -ms-transform: translate(0px , 40px) !important;
            transform: translate(0px , 40px) !important;
    width: 90% !important;
    scale: .9;
  }

  .player-top {
    -webkit-transform: translate(0px , 0px) !important;
    -ms-transform: translate(0px , 0px) !important;
        transform: translate(0px , 0px) !important;
  }


  .player-bottom {
    -webkit-transform: translate(0px , 50px) !important;
    -ms-transform: translate(0px , 50px) !important;
        transform: translate(0px , 50px) !important;
  }

  .playerImageBox {
    width: 4rem;
    height: 4rem;
  }

  .playerImageBox img {
    width: 5rem;
    height: 3.5rem;
  }

  .dice {
    width: 2.8rem;
    height: 2.8rem;
    right: 2%;
  }

  .logo {
    height: 10rem;
  }

  .game-modes-desktop {
    display: none;
    /* transform: translateY(-40px); */
  }


  .game-modes-mobile {
    display: block;
  }


  #conformation-modal {
    width: 90% !important;
    max-width: 20rem;
  }

  #join-private-room-modal {
    max-width: 25rem !important;
    max-height: 17rem !important;
  }

  #select-game-modal {
    width: 90% !important;
    height: 95% !important;
    min-height: 32rem !important;
  }

  #select-game-modal .counter {
    font-size: .8rem !important;
  }




  .playerImageBox {
    scale: .9;
  }

  .player-username {
    /* bottom: 20% !important; */
    position: absolute;
    /* left: 0%; */
    bottom: 1%;
    text-align: center;
    font-size: .6rem;
  }




  #select-game-modal button.Create {
    -webkit-transform: translateY(-26px);
        -ms-transform: translateY(-26px);
            transform: translateY(-26px);
    font-size: 1rem;
  }

  #win-loss-modal {
    width: 95% !important;
    height: 50% !important;
  }

  #profile-modal {
    width: 95% !important;
    height: 70% !important;
  }

  #menu-modal {
    width: 70% !important;
    height: 20%;
  }

  #join-room-modal {
    max-width: 22rem;
    max-height: 27rem !important;
  }

  #setting-modal {
    width: 50% !important;
    /* min-height: 17rem !important; */
    min-width: 20rem !important;
  }

  #select-game-type-modal {
    width: 33% !important;
    height: 20% !important;
    /* min-height: 20rem !important; */
  }

  #private-room-player-modal,
  #Themes {
    width: 90% !important;
    height: 95% !important;
  }

  .board-container {
    scale: 1;
  }

  #create-room-modal {
    width: 90% !important;
    height: 45% !important;
    min-height: 17rem !important;
    min-width: 15rem !important;
  }

  #create-room-modal .wrapper {
    padding-top: 1rem;
  }

  #create-room-modal button,
  #select-game-modal button {
    font-size: .7rem;
    padding: .25rem 1rem;
    margin-top: .5rem;
  }

  #select-pawn-modal,
  #rematch-modal {
    width: 70% !important;
    max-width: 20rem !important;
    max-height: 18rem !important;
    min-height: 15rem !important;
  }

  #menu-modal {
    max-width: 20rem !important;
    height: 18rem !important;
  }


  #Themes {
    max-width: 90% !important;
    max-height: 95% !important;
  }

  .board-theme {
    scale: .8;
  }

  #Themes .select-wrapper {
    margin-top: .1rem;
  }

  #Themes .select-button {
    scale: .7 !important;
  }

  #Themes button {
     padding: .6rem;
  }
}

@media screen and (max-width: 360px) and (max-height : 780px) { 
  .board-container {
    scale: .8 !important;
    position: relative;
    z-index: 10;
    height: 0%;
  }

  .Players {
    -webkit-transform: translate(0px , 20px) !important;
        -ms-transform: translate(0px , 20px) !important;
            transform: translate(0px , 20px) !important;
    width: 100%;
  }

}

@media screen and (max-width: 360px) and (max-height : 800px) { 
  .board-container {
    scale: .9 !important;
    height: 0%;
    position: relative;
    z-index: 10;
  }

  .Players {
    -webkit-transform: translate(0px , 20px) !important;
        -ms-transform: translate(0px , 20px) !important;
            transform: translate(0px , 20px) !important;
    width: 90%;
    /* height: 20%; */
  }

  .player-top {
    -webkit-transform: translate(0px , 0px) !important;
    -ms-transform: translate(0px , 0px) !important;
        transform: translate(0px , 0px) !important;
  }

  .player-bottom {
    -webkit-transform: translate(0px , 50px) !important;
    -ms-transform: translate(0px , 50px) !important;
        transform: translate(0px , 50px) !important;
  }

 
}


@media (orientation : landscape) and (max-height : 700px) {
  .board-container {
    scale: .9 !important;
    height: 0%;
    position: relative;
    z-index: 10;
  } 
 
 
  .lobby-name {
    font-size: 13px !important;
  }

  .main-container {
    margin-top: 6.5rem;
  }

  .Players {
    -webkit-transform: translate(0px) !important;
        -ms-transform: translate(0px) !important;
            transform: translate(0px) !important;
    width: 90% !important;
  }

  .playerImageBox {
    width: 5rem;
    height: 4rem;
  }

  .playerImageBox img {
    width: 5rem;
    height: 5rem;
  }

  .dice {
    width: 3.5rem;
    height: 3.5rem;
  }

  .logo {
    height: 15rem !important;
  }

  .game-modes-desktop {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-transform: translateY(-40px);
        -ms-transform: translateY(-40px);
            transform: translateY(-40px);
  }


  .game-modes-mobile {
    display: none !important;
  }

  #conformation-modal {
    width: 90% !important;
    max-width: 20rem;
  }

  #join-private-room-modal {
    max-width: 25rem !important;
    max-height: 17rem !important;
  }

  #select-game-modal {
    width: 90% !important;
    height: 95% !important;
    min-height: 32rem !important;
  }

  #select-game-modal .counter {
    font-size: .8rem !important;
  }




  .playerImageBox {
    scale: .9;
  }

  .player-username {
    /* bottom: 20% !important; */
    position: absolute;
    /* left: 0%; */
    bottom: 1%;
    text-align: center;
    font-size: .6rem;
  }




  #select-game-modal button.Create {
    -webkit-transform: translateY(-26px);
        -ms-transform: translateY(-26px);
            transform: translateY(-26px);
    font-size: 1rem;
  }

  #win-loss-modal {
    width: 95% !important;
    height: 50% !important;
  }

  #profile-modal {
    width: 95% !important;
    height: 70% !important;
  }

  #menu-modal {
    width: 70% !important;
    height: 20%;
  }

  #join-room-modal {
    max-width: 22rem;
    max-height: 27rem !important;
  }

  #setting-modal {
    width: 50% !important;
    /* min-height: 17rem !important; */
    min-width: 20rem !important;
  }

  #select-game-type-modal {
    width: 33% !important;
    height: 20% !important;
    /* min-height: 20rem !important; */
  }

  #private-room-player-modal,
  #Themes {
    width: 90% !important;
    height: 95% !important;
  }

  .board-container {
    scale: 1;
  }

  #create-room-modal {
    width: 90% !important;
    height: 45% !important;
    min-height: 17rem !important;
    min-width: 15rem !important;
  }

  #create-room-modal .wrapper {
    padding-top: 1rem;
  }

  #create-room-modal button,
  #select-game-modal button {
    font-size: .7rem;
    padding: .25rem 1rem;
    margin-top: .5rem;
  }

  #select-pawn-modal,
  #rematch-modal {
    width: 70% !important;
    max-width: 20rem !important;
    max-height: 18rem !important;
    min-height: 15rem !important;
  }

  #menu-modal {
    max-width: 20rem !important;
    height: 18rem !important;
  }


  #Themes {
    max-width: 90% !important;
    max-height: 83% !important;
  }

  .board-theme {
    scale: .8;
  }

  #Themes .select-wrapper {
    margin-top: 1rem;
  }


  #win-loss-modal {
     max-width: 60% !important;
     min-height: 22rem !important;
     margin-top: 20% !important;
  }

  .win-assets {
    top: -45% !important;
  }
 
  .ribbine {
    width: 100% !important;
    margin: auto;
  }
 
  .winDefetImg {
     width: 30%;
  }
}

@media (orientation : landscape) and (max-height : 400px) {
  .save-buton {
    display: none !important;
  }


  #profile-modal {
    max-width: 60% !important;
    min-height: 20rem !important;
 }

 .profiles {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
 }
 
 .profiles .text-wrapper  {
    width: 6rem !important;
    font-size: 10px !important;
    margin:1rem auto;
 }

  .board-container {
    scale: .59 !important;
    position: relative;
    z-index: 10;
  }

  .main-container {
    margin-top: 6.5rem;
  }

  .Players {
    -webkit-transform: translate(0px) !important;
        -ms-transform: translate(0px) !important;
            transform: translate(0px) !important;
    width: 97% !important;
    scale: .85 !important;
  }

  .playerImageBox {
    width: 5rem;
    height: 5rem;
    
  }

  .playerImageBox img {
    width: 5rem;
    height: 4.5rem;
  }

  .dice {
    width: 3.5rem;
    height: 3.5rem;
  }

  .logo {
    height: 0rem !important;
  }

  .main-nav,
  .ludo-nav {
     height: 3rem !important;
     display: flex;
     align-items: center;
  }

  .ludo-nav  .right {
     scale: .8;
  }

  .main-nav img {
     width: 2rem !important;
     transform: translateY(15px);
     /* height: 2rem !important; */
  }

  .game-modes-desktop {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-transform: translateY(-40px);
        -ms-transform: translateY(-40px);
            transform: translateY(-40px);
  }


  .game-modes-mobile {
    display: none !important;
  }

  #conformation-modal {
    width: 90% !important;
    max-width: 20rem;
  }

  #join-private-room-modal {
    max-width: 25rem !important;
    max-height: 17rem !important;
  }

  #select-game-modal {
    width: 90% !important;
    height: 95% !important;
    max-width: 22rem !important;
    min-height: 95% !important;
  }

 

  .playerImageBox {
    scale: .9;
  }

  .player-username {
    /* bottom: 20% !important; */
    position: absolute;
    /* left: 0%; */
    bottom: 1%;
    text-align: center;
    font-size: .6rem;
  }

  #select-game-modal .wrapper-container {
    height: 12rem;
  }

  #select-game-modal .counter {
    /* height: 12rem; */
    width: 100%;
  }



  #select-game-modal button {
    -webkit-transform: translateY(-16px);
        -ms-transform: translateY(-16px);
            transform: translateY(-16px);
  }

  #win-loss-modal {
    width: 95% !important;
    height: 50% !important;
  }

  #profile-modal {
    width: 95% !important;
    height: 70% !important;
  }

  #menu-modal {
    width: 70% !important;
    height: 20%;
  }

  #join-room-modal {
    max-width: 22rem;
    max-height: 27rem !important;
  }

  #setting-modal {
    /* width: 50% !important; */
    min-height: 17rem !important;
    min-width: 20rem !important;
  }

  #select-game-type-modal {
    width: 50% !important;
    height: 20% !important;
    min-height: 16.5rem !important;
  }

  #private-room-player-modal,
  #Themes {
    width: 90% !important;
    height: 95% !important;
  }

  .board-container {
    scale: 1;
  }

  #create-room-modal {
    width: 90% !important;
    height: 45% !important;
    min-height: 17rem !important;
    min-width: 15rem !important;
  }

  #create-room-modal .wrapper {
    padding-top: 1rem;
  }

  #create-room-modal button,
  #select-game-modal button {
    font-size: .7rem;
    padding: .25rem 1rem;
    margin-top: .5rem;
  }

  #select-pawn-modal,
  #rematch-modal {
    width: 70% !important;
    max-width: 20rem !important;
    max-height: 18rem !important;
    min-height: 15rem !important;
  }

  #menu-modal {
    max-width: 20rem !important;
    height: 18rem !important;
  }

  #Themes {
    max-width: 40rem !important;
    max-height: 95% !important;
  }

  #Themes .card-wrapper {
     height: 9rem !important;
     width: 7rem !important;
  }
    
  .board-theme {
     width: 10rem !important;
     font-size: .8rem !important;
  }

  #Themes .themes {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-orient: horizontal !important;
     -webkit-box-direction: normal !important;
         -ms-flex-direction: row !important;
             flex-direction: row !important;
     max-height: 12rem !important;
  }

  #Themes button {
     scale: .7;
     -webkit-transform: translateX(380px) translateY(10px);
         -ms-transform: translateX(380px) translateY(10px);
             transform: translateX(380px) translateY(10px);
  }

  .board-theme {
    scale: .8;
  }

  #Themes .select-wrapper {
    margin-top: 1rem;
  }

  #win-loss-modal {
    max-width: 50% !important;
    min-height: 80% !important;
    margin-top: 9% !important;
 }

 #win-loss-modal .footer  {
  margin: 0;
  transform: translateY(-20px);
  scale: .7;
}

 .win-assets {
   top: -20% !important;
 }

 .ribbine {
   width: 100% !important;
   margin: auto;
 }

 .winDefetImg {
    width: 30%;
 }

}

@media (orientation : landscape)  and (max-height : 300px) {
  
  .board-container {
    scale: .45 !important;
    height: 0%;
    position: relative;
    z-index: 10;
  }

  .main-container {
    margin-top: 6.5rem;
  }

  .Players {
    -webkit-transform: translate(0px,10px) !important;
        -ms-transform: translate(0px,10px) !important;
            transform: translate(0px,10px)!important;
    width: 85% !important;
    scale: .8;
  }

  .playerImageBox {
    width: 5rem;
    height: 5rem;
  }

  .playerImageBox img {
    width: 5rem;
    height: 4.7rem;
  }
  .dice {
    width: 3.5rem;
    height: 3.5rem;
  }

  .logo {
    height: 0rem !important;
  }

  .game-modes-desktop {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-transform: translateY(-40px);
        -ms-transform: translateY(-40px);
            transform: translateY(-40px);
  }


  .game-modes-mobile {
    display: none !important;
  }

 

  #conformation-modal {
    width: 90% !important;
    max-width: 20rem;
  }

  #join-private-room-modal {
    max-width: 25rem !important;
    max-height: 17rem !important;
  }

  #select-game-modal {
    width: 90% !important;
    height: 95% !important;
    max-width: 22rem !important;
    min-height: 95% !important;
  }

 

  .playerImageBox {
    scale: .9;
  }

  .player-username {
    /* bottom: 20% !important; */
    position: absolute;
    /* left: 0%; */
    bottom: 1%;
    text-align: center;
    font-size: .6rem;
  }

  #select-game-modal .wrapper-container {
    height: 10rem;
  }

  #select-game-modal .counter {
    /* height: 12rem; */
    width: 100%;
  }



  #select-game-modal button {
    -webkit-transform: translateY(-16px);
        -ms-transform: translateY(-16px);
            transform: translateY(-16px);
  }

  #win-loss-modal {
    width: 95% !important;
    height: 50% !important;
    scale: .9;
  }
  
 

  #profile-modal {
    width: 95% !important;
    height: 70% !important;
  }

  #menu-modal {
    width: 70% !important;
    height: 15% !important;
    max-height: 14rem !important;
  }

  #join-room-modal {
    max-width: 22rem;
    max-height: 27rem !important;
  }

  #setting-modal {
    /* width: 50% !important; */
    min-height: 16rem !important;
    min-width: 20rem !important;
    scale: .9;
  }

 
  #select-game-type-modal {
    width: 50% !important;
    height: 20% !important;
    min-height: 15rem !important;
  }

  #select-game-type-modal .modal-title {
     font-size: 1rem;
     padding: .1rem 0;
  }

  #select-game-type-modal .play { 
     scale: .7;
  }

  #private-room-player-modal,
  #Themes {
    width: 90% !important;
    height: 95% !important;
  }

  .board-container {
    scale: 1;
  }

  #create-room-modal {
    width: 90% !important;
    height: 45% !important;
    min-height: 15.5rem !important;
    min-width: 15rem !important;
  }


  #create-room-modal .card-wrapper {
    height: 12rem;
    scale: .8;
    padding-top: 0;
    margin-top: 0;

  }

  #create-room-modal .wrapper {
    padding-top: 1rem;
  }

  #create-room-modal button,
  #select-game-modal button {
    font-size: .7rem;
    padding: .25rem 1rem;
    margin-top: .5rem;
  }

  #select-pawn-modal,
  #rematch-modal {
    width: 70% !important;
    max-width: 20rem !important;
    max-height: 18rem !important;
    min-height: 15rem !important;
  }

  #menu-modal {
    max-width: 20rem !important;
    height: 18rem !important;
  }

  #Themes {
    max-width: 40rem !important;
    /* max-height: 95% !important; */
    max-height: 20rem;
  }

  #Themes .card-wrapper{
     height: 8rem !important;
  }

  #Themes .wrapper {
     padding-top: 1rem !important;
  }
    
  .board-theme {
     width: 6.5rem !important;
     font-size: .8rem !important;
  }

  #Themes .themes {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-orient: horizontal !important;
     -webkit-box-direction: normal !important;
         -ms-flex-direction: row !important;
             flex-direction: row !important;
     max-height: 11rem !important;
     width: 25rem;
  }

  #Themes button {
     scale: .5;
     -webkit-transform: translateX(500px) translateY(-40px);
         -ms-transform: translateX(500px) translateY(-40px);
             transform: translateX(500px) translateY(-40px);
  }


  .board-theme {
    scale: .8;
  }

  #Themes .select-wrapper {
    margin-top: 2rem;
  }

   .counter {
    margin-top: 1rem;
   }

  .counter-card {
      max-height: 12rem !important;
   }
   #select-game-modal button.Create {
     -webkit-transform: translateY(-40px);
         -ms-transform: translateY(-40px);
             transform: translateY(-40px);
   }
  
  #win-loss-modal {
    max-width: 50% !important;
    min-height: 80% !important;
    margin-top: 5% !important;
  }
  .ribbine {
     scale: .9;
  }
  .winDefetImg {
     width: 30%;
     top: 40%;
  }    
}

@media screen and (min-width: 700px) and (max-height : 600px) {
  .Players {
    scale: 1;
    width: 75%;
  }
  #profile-modal {
    max-width: 60% !important;
    max-height: 100% !important;
  }
}

@media screen and (max-height : 986px) {
  .board-containe {
    height: 7rem;
  }
}