@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* .players {
    @apply flex justify-between flex-row w-11/12 sm:w-8/12 md:w-9/12 pb-0 m-auto  md:pt-[2.5rem] lg:py-[7rem] xl:px-2 xl:w-auto xl:m-0 xl:flex-col items-center  rounded-xl;
  } */

  .counter-button {
    @apply outline outline-2 outline-[#FFB900] text-white rounded-md;
  }

  .profile-text {
    @apply text-[#ffffff] text-xl text-center font-light;
  }
  /* .purchase-button {
    @apply relative cursor-pointer opacity-90 hover:opacity-100 transition-opacity p-3 bg-black rounded-[10px] ring-1 ring-yellow-600 bg-gradient-to-t from-[#c40d8c] to-[#b322ff] active:scale-95 font-normal text-sm  hover:scale-[.98]
  } */
}

